<template>
  <div>
    <v-card>
      <v-card-title class="mb-0 pb">
        <div class="page-title text-center mx-auto">
          <h2
            class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center"
          >
            <span class="mb-0 pb-0">
              Fighter MarketPlace</span>
          </h2>
        </div>
      </v-card-title>
      <p class="text-center my-0 py-0 text-sm d-none">
        Trade Fighters
      </p>
      <v-card-text v-if="admin">
        <v-row>
          <v-col
            cols="3"
            class="offset-md-4"
          >
            <v-text-field
              v-model="fighterId"
              label="Fighter ID"
              @change="updateFighterId"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="3"
          >
            <v-btn
              :loading="addLoading"
              color="primary"
              x-small
              class="float-left mt-5"
              @blur="addUpdateFighter"
            >
              Add/Update Fighter
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="3"
            class="offset-md-4"
          >
            <v-text-field
              v-model="addFighterName"
              label="Fighter Name"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="3"
          >
            <v-btn
              :loading="addLoading"
              color="primary"
              x-small
              class="float-left mt-5"
              @blur="addUpdateFighterName"
            >
              Add/Update Fighter
            </v-btn>
          </v-col>
          <div class="float-right">
            <v-btn
              v-if="admin"
              color="primary"
              outlined
              x-small
              @click="updateAllMintedFighters()"
            >
              Update All Minted Fighters
            </v-btn>
            <v-btn
              v-if="admin"
              color="primary"
              outlined
              x-small
              @click="setPendingActive()"
            >
              Set pending active
            </v-btn>
            <v-btn
              v-if="admin"
              color="primary"
              outlined
              x-small
              @click="updateMissingImages()"
            >
              Update Missing Images
            </v-btn>
            <v-btn
              v-if="admin"
              color="primary"
              outlined
              x-small
              @click="updateAllRankings()"
            >
              Update All Rankings
            </v-btn>
          </div>
        </v-row>
        <div v-if="addedData > 2">
          {{ addedData }}
        </div>
      </v-card-text>
      <TeamTable
        :fighters="fighters"
        :marktet-place-view="true"
        :loading-table="loadingTable"
      ></TeamTable>
    </v-card>
    <br>
    <br>
  </div>
</template>

<script>
import {mdiAccountCashOutline} from '@mdi/js'
import axios from 'axios'
import dbFunctions from '@/db'
import TeamTable from '@/views/components/TeamTable.vue'

export default {
  components: {
    TeamTable,
  },
  setup() {
    return {
      icons: {
        mdiAccountCashOutline,
      },
    }
  },
  data() {
    return {
      fighters: [],
      loadingTable: '',
      model: null,
      fighterId: '',
      addFighterName: '',
      addLoading: false,
      addedData: {},
      user: this.$store.state.user,
      admin: this.$store.state.user.profile?.admin,
    }
  },
  mounted() {
    this.getFighters()
  },
  methods: {
    getFighters() {
      this.loadingTable = true
      dbFunctions.getAllFighters()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.fighters.push(doc.data())
          })
          this.loadingTable = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    updateFighterId(e) {
      this.fighterId = e
    },
    addUpdateFighter() {
      console.log('update fighter')
      this.addLoading = true
      const api = axios.create({
        baseURL: this.$baseURL,
        timeout: 10 * 60 * 1000, // whatever time you want
      })
      api.get(`fighter/?id=${this.fighterId}`).then(response => {
        console.log(response)
        this.addLoading = false
        this.addedData = response.data
        this.fighterId = ''
      })
    },
    addUpdateFighterName() {
      this.addLoading = true
      console.log('update fighter')
      const api = axios.create({
        baseURL: this.$baseURL,
        timeout: 10 * 60 * 1000, // whatever time you want
      })
      api.get(`fightername/?name=${this.addFighterName}`).then(response => {
        console.log(response)
        this.addLoading = false
        this.addedData = response.data
        this.fighterId = ''
      })
    },
    updateAllMintedFighters() {
      console.log('start update')
      dbFunctions.updateAllMintedFighters()
    },
    setPendingActive() {
      console.log('set pending active')
      dbFunctions.setPendingActive()
    },
    updateMissingImages() {
      console.log('update missing images')
      dbFunctions.updateMissingImages()
    },
    updateAllRankings() {
      const api = axios.create({
        baseURL: this.$baseURL,
        timeout: 10 * 60 * 1000, // whatever time you want
      })
      api.get('/rankings?update-all=all').then(response => {
        console.log(response)
        this.addLoading = false
      })
    },
  },
}
</script>
